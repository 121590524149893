<template>
  <div class="home">
    <v-container>
      <h1>Debug</h1>
      <v-sheet>
        <v-row>
          <v-col>
            <v-switch v-model="debugSwitch" label="Debug output"></v-switch>
          </v-col>
        </v-row>
      </v-sheet>
      <v-row>
        <v-col cols="6">
          <v-card>
            <v-card-title>Wörter</v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item @click="syncWordsConfig">
                  <v-list-item-title
                    >Snyc Wörterliste zu Config</v-list-item-title
                  >
                  <v-list-item-action>
                    <v-icon>mdi-reload</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card>
            <v-card-title>Files</v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item @click="syncWordFiles">
                  <v-list-item-content>
                    <v-list-item-title
                      >Sync Wörterdateien {{ count }}/{{ totalWords }}
                      {{ syncingWordFiles }}</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      Word: {{ currentWord }}<br />
                      File: {{ currentFile }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon v-if="!syncingWordFiles">mdi-reload</v-icon>
                    <v-progress-circular
                      :indeterminate="true"
                      v-else
                    ></v-progress-circular>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card>
            <v-card-title>Kategorien</v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item @click="syncCategoriesOnWords">
                  <v-list-item-title
                    >Re-Snyc Kategorieliste an Wörtern</v-list-item-title
                  >
                  <v-list-item-action>
                    <v-icon>mdi-reload</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card>
            <v-card-title>Kategorien</v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item @click="syncCategoriesConfig">
                  <v-list-item-title>Re-Snyc Kategorieliste</v-list-item-title>
                  <v-list-item-action>
                    <v-icon>mdi-reload</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */
import { mapGetters } from "vuex";

export default {
  name: "debug",
  components: {},
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      syncingWordFiles: "debug/syncingWordFiles",
      totalWords: "debug/totalWords",
      count: "debug/count",
      currentWord: "debug/currentWord",
      currentFile: "debug/currentFile",
      debug: "navigation/debug",
    }),
    debugSwitch: {
      get() {
        return this.debug;
      },
      set(value) {
        this.switchDebug(value);
      },
    },
  },

  methods: {
    switchDebug(event) {
      console.log(event);
      this.$store.dispatch("navigation/setDebug", { debug: event });
    },
    syncCategoriesOnWords() {
      this.$store.dispatch("debug/updateCategoriesOnWords");
      //   if (confirm("wirklich sync kategorien an wörtern?")) {
      //     alert("disabled");
      //   }
    },
    syncCategoriesConfig() {
      if (confirm("wirklich sync kategorien?")) {
        alert("disabled");
        // this.$store.dispatch("debug/syncCategoriesConfig");
      }
    },
    syncWordsConfig() {
      if (confirm("wirklich sync wörter?")) {
        // alert("disabled");
        this.$store.dispatch("debug/syncWordsConfig");
      }
    },
    syncWordFiles() {
      if (confirm("wirklich sync files?")) {
        this.$store.dispatch("debug/syncWordFiles");
      }
    },
  },
};
</script>
